<template>
  <router-link class="blog-item" :to="{name: 'Article', params: { id }}">
    <div class="blog-item__img-wrapper">
      <!-- <img class="blog-item__img" src="../../assets/blogs/Article.png" alt="article-img"> -->
    </div>
    <section class="blog-item__info">
      <BaseChip :type="type.value">{{ type.name }}</BaseChip>
      <h4>{{ title }}</h4>
      <p>{{ text }}</p>
      <time>{{ date }}</time>
    </section>
  </router-link>
</template>

<script>
import BaseChip from "../Base/BaseChip.vue"

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  components: { BaseChip }
}
</script>

<style scoped>
.blog-item {
  display: grid;
  grid-template-columns: 13.125em 1fr;
  gap: 1.25em;

  font-family: Raleway, sans-serif;
  font-style: normal;
  text-decoration: none;
}
.blog-item:hover .blog-item__info h4 {
  text-decoration: underline;
}
@media (max-width: 480px) {
  .blog-item {
    grid-template-columns: 1fr;
  }
}

.blog-item__img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  min-height: 7.25em;
  background: url('../../assets/blogs/Article.png') 50%/cover no-repeat;
}
/* .blog-item__img {
  width:100%;
  height:100%;
  object-fit: contain;
} */

.blog-item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-align: left;
  font-weight: normal;
}

.blog-item__info h4 {
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--black);
  margin: var(--md) 0 var(--sm);
  line-height: 115%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 480px) {
  .blog-item__info h4 {
    font-size: 1.25rem;
  }
}

.blog-item__info p {
  font-size: 0.875rem;
  color: var(--dark-gray);
  margin: 0 0 var(--sm);
  line-height: 140%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-item__info time {
  font-size: 0.875rem;
  color: var(--light-gray);
  line-height: 140%;
}
</style>
