<template>
  <article class="article">
    <div class="article__layout">
      <div class="article__back">
        <button class="article__back_btn" @click="$router.go(-1)">
          <ArrowIcon/>
        </button>
      </div>

      <main class="article__main">
        <header class="article__header">
          <BaseChip class="article__chip">Маркетинг</BaseChip>
          <h2>Название статьи оно наверное длинное получится</h2>
          <time>12 апреля 2014</time>
        </header>

        <section class="article__sub-header">
          <article class="article__author">
            <div class="article__author_icon"></div>
            <div class="article__author_info">
              <span class="article__author_name">Автор Авторов</span>
              <span class="article__author_position">Маркетолог</span>
            </div>
          </article>

          <span class="article__views">564 просмотра</span>
        </section>

        <img src="../assets/article/article1.png" alt="" class="article__img">

        <section class="article__text">
          <h4>Почему SEO важно?</h4>
          <p>
            Органический трафик достается компании бесплатно, при достижение хороших результатов они будут сохранятся даже после окончания работ. Органический трафик наиболее конверсируемый (хз есть ли такое слово) и имеет лучшеи поведенческие факторы. Клиенты которые нашли вас в органической выдачи являются более лояльными так как ваш сайт выбор Яндекса и Googla в это отрасли и по этому запросу
          </p>

          <h4>Почему SEO важно?</h4>
          <p>
            Органический трафик достается компании бесплатно, при достижение хороших результатов они будут сохранятся даже после окончания работ. Органический трафик наиболее конверсируемый (хз есть ли такое слово) и имеет лучшеи поведенческие факторы. Клиенты которые нашли вас в органической выдачи являются более лояльными так как ваш сайт выбор Яндекса и Googla в это отрасли и по этому запросу
          </p>
          <p>
            Органический трафик достается компании бесплатно, при достижение хороших результатов они будут сохранятся даже после окончания работ. Органический трафик наиболее конверсируемый (хз есть ли такое слово) и имеет лучшеи поведенческие факторы. Клиенты которые нашли вас в органической выдачи являются более лояльными так как ваш сайт выбор Яндекса и Googla в это отрасли и по этому запросу
          </p>
          <img src="../assets/article/article2.png" alt="" class="article__img">

          <h4>Почему SEO важно?</h4>
          <p>
            Органический трафик достается компании бесплатно, при достижение хороших результатов они будут сохранятся даже после окончания работ. Органический трафик наиболее конверсируемый (хз есть ли такое слово) и имеет лучшеи поведенческие факторы. Клиенты которые нашли вас в органической выдачи являются более лояльными так как ваш сайт выбор Яндекса и Googla в это отрасли и по этому запросу
          </p>
        </section>
      </main>
    </div>
    <aside class="article__aside">
      <h3>Похожие статьи</h3>
      <section>
        <BlogItem v-for="(article, index) in articles" :key="index" v-bind="article"/>
      </section>
    </aside>
  </article>
</template>

<script>
import BaseChip from '../components/Base/BaseChip.vue'
import BlogItem from '../components/Blog/BlogItem.vue'
import ArrowIcon from '../components/Icons/ArrowIcon.vue'
export default {
  components: { BaseChip, BlogItem, ArrowIcon },
  data() {
    return {
      articles: [
        {
          id: this.$route.params.id + 1,
          image: '',
          type: { name: 'Маркетинг', value: 'marketing'},
          title: 'Название статьи оно наверное длинное получится',
          text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
          date: '12.04.2014'
        },
        {
          id: this.$route.params.id + 2,
          image: '',
          type: { name: 'Маркетинг', value: 'marketing'},
          title: 'Название статьи оно наверное длинное получится',
          text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
          date: '12.04.2014'
        }
      ]
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(val) {
        this.articles = [
          {
            id: +val.params.id + 1,
            image: '',
            type: { name: 'Маркетинг', value: 'marketing'},
            title: 'Название статьи оно наверное длинное получится',
            text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
            date: '12.04.2014'
          },
          {
            id: +val.params.id + 2,
            image: '',
            type: { name: 'Маркетинг', value: 'marketing'},
            title: 'Название статьи оно наверное длинное получится',
            text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
            date: '12.04.2014'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.article {
  width: 81.875em;
  margin: 4.375em auto var(--section-bottom-margin);
  box-sizing: border-box;
}
@media (max-width: 1367px) {
  .article {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  .article {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .article {
    margin: 3.125em auto var(--section-bottom-margin);
  }
}
@media (max-width: 426px) {
  .article {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  .article {
    padding: 0 15px;
  }
}

.article__back_btn {
  border: none;
  background: transparent;
  cursor: pointer;
}
@media (max-width: 965px) {
  .article__back {
    grid-column: 2;
  }
  .article__back_btn {
    float: left;
    margin-bottom: 1.875em;
  }
}
@media (max-width: 425px) {
  .article__back_btn {
    margin-bottom: 1.5em;
  }
}

.article__layout {
  display: grid;
  grid-template-columns: 1fr minmax(1px, 43.75em) 1fr;
}

.article__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5em;
}

.article__header h2 {
  margin-bottom: 0.416em;
}
@media (max-width: 425px) {
  .article__header h2 {
    font-size: 1.625rem;
  }
  .article__header {
    margin-bottom: 1.875em;
  }
}

.article__header time {
  font-size: 0.875rem;
  color: var(--light-gray);
  line-height: 140%;
}

.article__chip {
  margin-bottom: 1.075em;
}

.article__sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25em;
  align-items: center;
}
@media (max-width: 425px) {
  .article__sub-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.article__author {
  display: flex;
}
@media (max-width: 425px) {
  .article__author {
    margin-bottom: 0.9375em;
  }
}

.article__author_icon {
  background: url('../assets/article/author.png') 50%/cover no-repeat;
  width: 3.125em;
  height: 3.125em;
  margin-right: 0.625em;
}

.article__author_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--black);
}

.article__author_position {
  color: var(--gray);
}

.article__views {
  font-size: 1.125rem;
  line-height: 140%;
  color: var(--gray);
}
@media (max-width: 425px) {
  .article__views {
    font-size: 0.875rem;
  }
}

.article__main {
  grid-column: 2;
}

.article__main img {
  width: 100%;
  margin: 1.25em 0;
}

.article__main h4 {
  margin: 0.83333em 0;
}

.article__main p {
  font-size: 1.125rem;
  line-height: 140%;
  color: var(--gray);
  margin-bottom: 0.83333em;
}

.article__aside h3 {
  margin: 1.5625em 0 0.9375em;
}
@media (max-width: 425px) {
  .article__main p {
    font-size: 0.875rem;
  }
  .article__main h4 {
    font-size: 1.25rem;
}

  .article__aside h3 {
    font-size: 1.625rem;
  }
}

.article__aside section {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 5.0625em;
}
@media (max-width: 1366px) {
  .article__aside section {
    gap: 4.6875em;
  }
}

@media (max-width: 1025px) {
  .article__aside section {
    grid-template-columns: 1fr;
    gap: 1.8755em;
  }
}

@media (max-width: 768px) {
  .article__aside section {
    gap: 1.25em;
  }
}

</style>
